@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");
@import url("https://fonts.googleapis.com/css?family=Dela Gothic One");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:700,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap");
@import url("https://db.onlinewebfonts.com/c/3e9b8901f214bcb84ef81951d4052efe?family=Biome+W01+Wide+Bold");
@import url(https://db.onlinewebfonts.com/c/1c6b6cddab6609324d9016562358e0cd?family=Biome+W04+Regular);

/* .video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 30vw;
    height: 100vh;
    overflow: hidden;
    z-index: -1; 
}

video {
    object-fit: cover;
    width: 100%;
    height: 100%;
} */
/* .css-ahj2mt-MuiTypography-root,
.css-wgusnr-MuiTypography-root {
  font-family: unset !important;
} */
/* .css-98vrab-MuiPaper-root-MuiCard-root {
  border-color: #0e121b;
}
.css-wgusnr-MuiTypography-root {
  color: #0e121b !important  ;
} */

.YunitHomeContainer {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Overlay color with transparency */
}

.sheen-container {
  position: relative;
  width: 100%;
  height: 50vw;
  max-height: 90vh;
  overflow: hidden;
  container-name: sidebar;
}

.sheen-container > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.5s ease-in-out;
}

.sheen-container-overlay-text {
  position: absolute;
  top: 15%;
  right: 10%;
  /* transform: translate(-50%, -50%); */
  color: white;
  font-size: 20px;
  font-weight: 700;
  font-family: "Biome W04 Regular";
  /* position: absolute; */
  /* transform: translate(0%, -50%); */
  font-size: 1.5em;
  color: #000;
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 20px;
  

  /* margin-left: 60%; */
  /* width: fit-content; */
  /* animation: fadeInRight 2s forwards; */
}

.sheen-container:hover img:nth-child(2) {
  transform: scale(1.1);
}

.overlay-text .hello {
  font-size: 50px;
  margin: 0;
}

.sheen-container-start-anchor.fade-right + .sheen-container .heading1 {
  animation: fadeInLeft 3s ease forwards;
}

.sheen-container-overlay-text img {
  height: 20px;
  padding-right: 20px;
  object-fit: contain;
}

.sheen-container-overlay-text .hello h4 {
  font-size: 1rem;
}

@media screen and (min-width: 1000px) {
  .sheen-container-overlay-text .hello h4 {
    font-size: 1em;
  }
}

.sheen-container-heading-overlay-text {
  position: absolute;
  width: 100%;
  height: 100%;
}

.sheen-container-heading-overlay-text .heading1 {
  position: absolute;
  top: 3vw;
  left: 5%;
  font-size: 70px;
  font-weight: 700;
  font-family: "Biome W04 Regular";
  color: #000;
}

.sheen-container-heading-overlay-text .heading2 {
  position: absolute;
  margin: 0;
  font-weight: 800;
  font-style: normal;
  color: transparent;
  color: #47aad9;
  font-size: 70px;
  bottom: 11vw;
  right: 5%;
}

.overlay1 {
  position: absolute;
  top: 190vh;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0); /* Overlay color with transparency */
}
.css-wgusnr-MuiTypography-root{
  font-size: 20px;
}
.sheendiv {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.gallery {
  background: #f5ffff;
}

.gallery-cell {
  width: 66%;
  height: 200px;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0);
  counter-increment: gallery-cell;
}

/* cell number */
.gallery-cell:before {
  display: block;
  text-align: center;
  content: counter(gallery-cell);
  line-height: 200px;
  font-size: 80px;
  color: #0e121b;
}
.flickity-button {
  color: #0e121b;
}

.YunitHome {
  width: 100%;
  height: 100%;
  /* object-fit: cover; 
  display: flex; */
  object-fit: contain;
  height: unset;
}
.YunitHomeContainer {
  height: 100%;
}
.imageContainer {
  background-color: #131e38de;
  height: 100%;
}
.hometext h1 {
  margin: 0;
  font-size: 40px;
  /* font-family: "Dela Gothic One", sans-serif; */
  font-weight: 800;
  font-style: normal;
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255, 255, 255); 
  color: transparent;
  background-image: none; */
  /* background-image:radial-gradient(circle at 12.3% 19.3%, rgb(85, 88, 218) 0%, rgb(95, 209, 249) 100.2%); */
  color: transparent;
  color: #47aad9;
}
.hometext h2 {
  /* color: white; */
  margin: 0;
  text-align: end;
  /* font-family: "Dela Gothic One", sans-serif; */
  /* font-family: "Biome W01 Wide Bold"; */
  font-family: "Biome W04 Regular";
  font-weight: 400;
  font-style: normal;
  font-weight: 10;
  /* -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-text-stroke: .2px rgb(76, 93, 218); */
  color: aliceblue;
  background-image: none;
}
.usImg {
  width: 100;
  display: flex;

  justify-content: space-evenly;
}
.usImg img {
  width: 80%;
}
.container2 {
  background-color: #0e121b;
}
.teamh2 {
  padding: 40px;
  text-align: center;
  margin: 0;
  font-size: 40px;
  /* font-family: sans-serif;  */
  font-family: "Biome W04 Regular";
  color: #f5ffff;
  /* -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgb(0, 0, 0); */
  opacity: 0;
}
.teamh2.fade-in {
  animation: fadeInDown 3s ease forwards;
}

/* .css-1uzrjc8-MuiSvgIcon-root {
  display: none !important;
} */

.sheen img {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
.values {
  display: flex;
  flex-wrap: wrap;
}

.supportH {
  padding-top: 4%;
  text-align: center;
  font-size: 40px;
  color: #0e121b;
}

.hometext {
  display: flex;
  flex-direction: column;
  height: 70%;
  align-items: flex-end;
  justify-content: center;
  padding-top: 0;
  animation: fadeInleft 3s ease forwards;
  /* padding-right: 5%; */
  padding-top: 10%;
}
.whitelogo {
  animation: fadeInright 3s ease forwards;
}
.whitelogotext {
  animation: fadeInright 3s ease forwards;
}

@keyframes fadeInright {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media screen and (min-width: 1100px) {
  .hometext h1 {
    font-size: 60px; /* Adjust font size for wider screens */
  }
}

.we {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5%;
  padding-top: 0%;
  align-items: center;
  background-color: #0e121b;
}
.we.fade-in {
  animation: fadeInDown 3s ease forwards;
}
.usData {
  font-weight: 300;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.usData p {
  color: #f5ffff;
}
/* .css-98vrab-MuiPaper-root-MuiCard-root {
  border-color: #0e121b !important;
} */
.usImg.fade-right {
  animation: fadeIn 3s ease forwards;
}
.usData.fade-right {
  animation: fadeIn 3s ease forwards;
}

.heading2.fade-right {
  animation: fadeIn 5s ease forwards;
}
.a3-container {
  text-align: center;
  margin-top: 100px;
}
/* .a3-heading.fade-in {
  animation: fadeInDown 3s ease forwards;
} */

.a3-heading {
  font-size: 48px;
  font-weight: 500;
  color: #333; /* Adjust color if needed */
  margin-bottom: 10px;
}

.subheading {
  font-size: 40px;
  font-weight: 700;
  padding-bottom: 2%;
}
.a3-heading.fade-in {
  animation: fadeInDown 3s ease forwards;
}
.subheading .fade-right {
  animation: fadeInUp 5s ease forwards;
}

.subheading .highlight {
  color: #1ca0de;
}

.separator {
  color: black;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInleft {
  0% {
    opacity: 0;
    transform: translateX(0px);
  }
  100% {
    opacity: 1;
    transform: translateX(-50px);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* boxes */

/* .container {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-template-rows: auto;
  grid-gap: 4% 4%;
  padding: 10%;
  padding-top: 0%;
  padding-bottom: 0%;
}

.container .box {
  position: relative;
  height: 400px;
  background: #f1f4ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  border-radius:50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.container .box:before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: #FFF;
  transform: skew(0.5deg, 0.5deg);
  z-index: -1;
  border-radius: 50px;
}

.container .box:nth-child(1):before {
  background: linear-gradient(315deg, #FF0057, #E64A19);
}

.container .box:nth-child(2):before {
  background: linear-gradient(315deg, #89FF00, #00BCD4);
}

.container .box:nth-child(3):before {
  background: linear-gradient(315deg, #E91E63, #5D02FF);
}

.container .box:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255,255,255,0.05);
  pointer-events: none;
}

.content {
  position: relative;
  padding: 20px;
  transform: translateY(40px);
}

.content h2 {
  position: absolute;
  top: -60px;
  right: 20px;
  margin: 0;
  padding: 0;
  font-size: 10em;
  color: rgba(0, 0, 0, 0.05);
  transition: 0.5s;
}

.box:hover .content h2 {
  top: -140px;
}

.box .content h3 {
  margin: 0 0 10px;
  padding: 0;
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}

.box .content p {
  margin: 0;
  padding: 0;
  color: #000000;
  font-size: 16px;
}

.box .content a {
  position: relative;
  margin: 20px 0 0;
  padding: 10px 20px;
  text-decoration: none;
  border: 1px solid #000000;
  display: inline-block;
  color: #000000;
  transition: 0.5s;
  transform: translateY(-40px);
  opacity: 0;
  visibility: hidden;
}

.box:hover .content a {
  position: relative;
  margin: 20px 0 0;
  padding: 10px 20px;
  text-decoration: none;
  border: 1px solid #000000;
  display: inline-block;
  color: #000000;
  transition: 0.5s;
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}

.box .content a:hover {
  color: #000;
  background: #FFF;
  
} */

.sheen {
  margin-top: 5%;
}

:root {
  --skew-angle: 0deg;
}

*,
*:before,
*:after {
  /* box-sizing: inherit; */
}

.skewclass {
  background-color: hsl(222, 6%, 18%);
  color: hsl(225, 100%, 1%);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  border-radius: 50px;
}

.content-area {
  padding: 2rem;
  width: 100%;
  align-self: end;
}

.skew {
  position: relative;
  /* background-image: linear-gradient(180deg, hsl(222, 10%, 8%) 0, hsl(222, 20%, 3%) 100%); */
  background-color: #f5ffff;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 100px;
  transform: skewY(var(--skew-angle));
  /* border-radius:50px; */
  /* animation: moveRight 2s forwards;  */
  /* padding-left: 2%; */
  padding-bottom: 2%;
  padding-top: 0%;
}

.bg-image {
  background-color: hsl(222, 80%, 2%);
  background-size: cover;
  background-position: center 80%;
}
.points {
  display: flex;
  flex-wrap: wrap;
  gap: 4%;
  row-gap: 20px;
  padding-top: 5%;
  padding-bottom: 5%;
}
.points p {
  font-family: unset;
}
.points.fade-in {
  animation: moveRightme 2s ease forwards;
}
.MuiPaper-root {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

/* .skew:hover .bg-image {
  animation: moveRightme 2s forwards;
} */

.bg-image.fade-right {
  animation: moveRightme 2s ease forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes moveRightme {
  from {
    transform: skew(0deg) translateX(-20px);
  }
  to {
    transform: skew(0deg) translateX(0px);
  }
}

.top-left {
  justify-self: end;
}

.top-right {
  background-image: url("../../public/condensor.jpg");
  background-size: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.bottom-left {
  background-image: url("../../public/vehicle.jpg");
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  background-size: cover;
}

.top-left,
.top-right {
  transform: skew(0deg);
}

.bottom-left,
.bottom-right {
  transform: skew(0deg);
}

.top-right,
.bottom-right {
  transform-origin: left;
}

.top-left,
.bottom-left {
  transform-origin: right;
  padding-right: 0;
}

@keyframes moveRight {
  0% {
    transform: skewY(var(--skew-angle)) translateX(-20px);
  }
  100% {
    transform: skewY(var(--skew-angle)) translateX(0px); /* Adjust the value as needed */
  }
}
@keyframes moveLeft {
  0% {
    transform: skewY(var(--skew-angle)) translateX(20px); /* Adjust the value as needed */
  }
  100% {
    transform: skewY(var(--skew-angle)) translateX(0px);
  }
}

@media (max-width: 800px) {
  .skew > div {
    grid-column-start: 0;
    grid-column-end: 1;
    transform-origin: center;
  }

  .top-left {
    justify-self: start;
  }

  .bg-image {
    height: 400px;
  }

  .bottom-left {
    grid-row: 4;
  }
}

.maincontentsli {
  margin-top: 5%;
  background-image: url("../../public/imgword.png");

  padding-bottom: 5%;
}

.benefit-item.slide-in-bottom {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3); /* Optional: adjust shadow properties */
  border-radius: 20px;
}
.benefit-item.slide-in-bottom:hover {
  transform: scale(1.1); /* Zoom on hover */
}

.values {
  padding: 4%;
}

.values {
  display: flex;
  gap: 3%;
  justify-content: center;
}

.maincontentsli {
  /* padding-left: 30%; */
  display: flex;
  flex-wrap: wrap;
}
.statw {
  height: 50%;
  /* width: 50%; */
  margin: 40px auto 0;
  width: 1400px;
  text-align: end;
  overflow: visible;
  position: relative;
  font-size: 40px;
  font-style: italic;
  margin-right: 10px;
  font-weight: 400;
}
.content-slider {
  /* margin-left: 20%; */
  padding-top: 5%;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
}
.quote span {
  color: rgb(0, 255, 0);
}
.slider {
  height: 50%;
  width: 100%;
  margin: 40px auto 0;
  /* padding-left: 2%; */
  overflow: visible;
  position: relative;
}

.mask {
  overflow: hidden;
  height: 50%;
}

.slider ul {
  margin: 0;
  padding: 0;
  position: relative;
}

.slider li {
  width: 680px;
  height: 50%;
  position: absolute;
  top: -325px;
  list-style: none;
}

.slider .quote {
  font-size: 40px;
  font-style: italic;
}

.slider .source {
  font-size: 20px;
  text-align: right;
}

.slider li.anim1 {
  animation: cycle 7s linear infinite;
}

.slider li.anim2 {
  animation: cycle2 7s linear infinite;
}

.slider li.anim3 {
  animation: cycle3 7s linear infinite;
}

.slider li.anim4 {
  animation: cycle4 7s linear infinite;
}

.slider li.anim5 {
  animation: cycle5 7s linear infinite;
}

/* .slider:hover li {
  animation-play-state: paused;
} */

@keyframes cycle {
  0% {
    top: 0px;
  }
  4% {
    top: 0px;
  }
  16% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  20% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
  21% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
  50% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
  92% {
    top: -325px;
    opacity: 0;
    z-index: 0;
  }
  96% {
    top: -325px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}

@keyframes cycle2 {
  0% {
    top: -325px;
    opacity: 0;
  }
  16% {
    top: -325px;
    opacity: 0;
  }
  20% {
    top: 0px;
    opacity: 1;
  }
  24% {
    top: 0px;
    opacity: 1;
  }
  36% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  40% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
  41% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes cycle3 {
  0% {
    top: -325px;
    opacity: 0;
  }
  36% {
    top: -325px;
    opacity: 0;
  }
  40% {
    top: 0px;
    opacity: 1;
  }
  44% {
    top: 0px;
    opacity: 1;
  }
  56% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  60% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
  61% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes cycle4 {
  0% {
    top: -325px;
    opacity: 0;
  }
  56% {
    top: -325px;
    opacity: 0;
  }
  60% {
    top: 0px;
    opacity: 1;
  }
  64% {
    top: 0px;
    opacity: 1;
  }
  76% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  80% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
  81% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    top: -325px;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes cycle5 {
  0% {
    top: -325px;
    opacity: 0;
  }
  76% {
    top: -325px;
    opacity: 0;
  }
  80% {
    top: 0px;
    opacity: 1;
  }
  84% {
    top: 0px;
    opacity: 1;
  }
  96% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  100% {
    top: 325px;
    opacity: 0;
    z-index: 0;
  }
}

/* .wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  height: 50vh;
  overflow:visible;
  white-space: wrap; 
  gap: 5%;
  animation: scrollAnimation 2s linear infinite;
  animation-play-state: running;  
} */

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

/* .supportH{
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: rgb(0, 0, 0);
  color: transparent;
  background-image: none;
} */

.wrapper {
  overflow: hidden;
  padding: 60px 0;
  white-space: nowrap;
  position: relative;
  display: flex;
}

.wrapper:before,
.wrapper:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

/* .wrapper:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(13, 11, 11, 0.063));

}

.wrapper:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(13, 11, 11, 0.063));
} */
/* .css-18k87ye-MuiTypography-root {
  color: #f5ffff;
} */
.wrapper:hover .wrapper-slide {
  animation-play-state: paused;
}

.wrapper-slide {
  display: inline-block;
  animation: 60s slide infinite linear;
}

.wrapper-slide .items {
  /* height: 100px; */
  margin: 0 40px;
}

.items {
  display: inline-block;
  width: fit-content;
  /* height: 200px; */
  /* background: #f1f1f1; */
  margin: 0 10px;
  /* border-radius: 10px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}
.MuiPaper-root{
  width: 100% !important;
  box-shadow: none;
 }


@keyframes scrollAnimation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg); /* Or any other angle you desire */
  }
}

.snow {
  transition: transform 2s ease; /* Add a smooth transition effect */
}

.snowflake {
  height: 20px;
  
}

.whitelogo {
  height: 25%;
  padding-left: 3%;
  padding-top: 3%;
}
.whitelogotext {
  height: 25%;
  padding-left: 3%;
  padding-top: 3%;
  font-size: 80px;
}
.feature-cardbronze1{
width: 60%;
}

@media (min-width: 0px) and (max-width: 450px) {
  .hometext h1 {
    font-size: 20px;
  }
  .hometext h2 {
    font-size: 8px;
  }
  .hometext {
    padding-top: 10%;
    width: 430px;
  }
  .whitelogo {
    height: 30%;
  }
  .usData p{
    font-size: 10px;
  }

  .a3-container{
    padding: 5px;
    margin-top: 8%;

  }
  .YunitHome {
    object-fit: contain;
    height: unset;
  }
  .whitelogo {
    padding-top: 20px;
  }
  .overlay {
    border: none;
  }
  .teamh2 {
    padding: 5%;
    font-size: 20px;
    -webkit-text-stroke-width: unset;
    -webkit-text-stroke-color: unset;
    /* color: #000; */
  }
  /* .css-d3wcwz-MuiTypography-root {
    font-size: 30px !important;
  } */
  .skew {
    margin-top: 50px;
  }
  .usData {
    text-align: center;
    font-weight: 400;
    font-style: normal;
  }
  .usImg img {
    width: 60%;
    margin: 10px;
  }

  .YunitHomeContainer {
    object-fit: contain;
    height: unset;
  }
  .a3-heading,
  .subheading {
    font-size: 20px;
  }
  .content-area {
    padding: 2%;
  }
  .values {
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 20px;
    margin: 10px;
  }
  /* .css-98vrab-MuiPaper-root-MuiCard-root {
    width: 100% !important;
  } */
   
  .bg-image {
    margin-right: 8px;
    height: 200px;
    border-radius: 10px;
  }
  .supportH {
    font-size: 30px;
    color: #000;
  }
  .para2 {
    display: none;
  }
  .content-area h2 {
    margin: 10px;
    margin-bottom: 20px;
  }
  .points {
    padding-left: 18px;
  }
  .bg-image {
    margin: 8px;
  }
  .MuiTypography-root {
    margin-right: 50px;
  }
}

@media (min-width: 0px) and (max-width: 900px) {
  /* .css-1lfs2tq-MuiTypography-root {
    color: #000 !important;
  }
  .css-i4bv87-MuiSvgIcon-root {
    color: black !important;
  }
  .css-2uchni {
    display: none;
  }
  .css-z5gn4u-MuiSvgIcon-root {
    display: none !important;
  } */
  .we .usImg {
    width: 100%;
  }
  .usData {
    padding-top: 20px;
  }

  .we {
    flex-direction: column;
  }
  .maincontentsli {
    display: none;
  }
  .imageContainer {
    height: 95%;
  }
  .whitelogo {
    height: 30%;
  }
  .hometext {
    padding-right: 0;
  }
  .wrapper:hover .wrapper-slide {
    animation-play-state: unset;
  }
  .MuiTypography-root {
    margin-right: 40px;
  }
  .MuiPaper-root{
    width: 100% !important;
   }
}

@media (min-width: 450px) and (max-width: 900px) {
  .hometext h2 {
    font-size: 15px;
  }
  .values {
    margin-top: 100px;
  }
}
@media (min-width: 900px) and (max-width: 1250px) {
  p {
    font-size: 15px;
  }
  .maincontentsli {
    display: none;
  }
}

@media (min-width: 1250px) {
  p {
    font-size: 17px;
  }
  .usImg img {
    width: 60%;
  }
  
  
}
@media (min-width: 450px) {

.homemobdiv{
  display: none;
}
}
@media (max-width: 450px) {

  .homemobdiv img{
    width: 100%;

  }
  }
@media (max-width: 1250px) {
  .sheen-container{
    display: none;
  }
}


@media (min-width: 450px) and (max-width: 750px) {
  /* .css-98vrab-MuiPaper-root-MuiCard-root {
    width: 100% !important;
    min-width: 300px;
    margin: 10px;
  } */
}
/* src/App.css */
.app {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  background-color: #f5ffff;
}

.card {
  width: 20%;
  background-color: transparent;
  border: solid 1px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  text-align: center;
  transition: transform 0.3s ease; /* Add transition for smooth effect */
  margin: 10px;
}

.card:hover {
  transform: scale(1.05); /* Zoom effect on hover */
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  justify-content: center;
}

.card-icon {
  width: 20%;
  margin-bottom: 10px;
}

.card-title {
  font-size: 24px;
  font-weight: bold;
  color: green;
}

.card-description {
  font-size: 16px;
  color: #555;
}

/* Unique class names */
.card-reduction {
  /* Add any specific styles for this card */
}

.card-ease-of-use {
  /* Add any specific styles for this card */
}

.card-savings {
  /* Add any specific styles for this card */
}

.card-environment {
  /* Add any specific styles for this card */
}
